import LoaderRing from './LoaderRing';
import LoaderSize from './LoaderSize';
import classNames from '@/external/classNames';

type Props = {
  color?: string;
  size?: LoaderSize;
  ariaLabel?: string;
};

const getSize = (size?: LoaderSize) => {
  switch (size) {
    case '2xs':
      return 'w-4 h-4';
    case 'xs':
      return 'w-6 h-6';
    case 'sm':
      return 'w-8 h-8';
    case 'lg':
      return 'w-12 h-12';
    default:
      return 'w-10 h-10';
  }
};

const Loader: FC<Props> = ({
  color = 'border-hit',
  size = 'md',
  ariaLabel = 'Loading',
}) => (
  <div
    aria-label={ariaLabel}
    role="alert"
    aria-busy="true"
    className={classNames(getSize(size), 'relative rounded-full')}
  >
    <LoaderRing color={color} delay="-0.45s" />
    <LoaderRing color={color} delay="-0.3s" />
    <LoaderRing color={color} delay="-0.15s" />
    <LoaderRing color={color} delay="0s" />
  </div>
);

export default Loader;
