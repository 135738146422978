import classNames from "@/external/classNames";

const borderClasses = 'border-4 border-solid';

type Props = {
  color: string;
  delay: string;
};

const LoaderRing: FC<Props> = ({ color, delay }) => (
  <div
    className={classNames(
      borderClasses,
      color,
      'absolute w-full h-full rounded-full animate-[rotation_1.2s_cubic-bezier(0.5,0,0.5,1)_infinite] border-x-transparent border-b-transparent',
    )}
    style={{ animationDelay: delay }}
    role="presentation"
  />
);

export default LoaderRing;
