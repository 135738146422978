import Variants from '../types/Variants';

const getLoaderColor = (variant?: Variants): string => {
  switch (variant) {
    case 'secondary':
    case 'outline':
      return 'border-bulma';
    case 'ghost':
      return 'border-trunks';
    default:
      return 'border-goten';
  }
};

export default getLoaderColor;
