import getLgPadding from '../padding/getLgPadding';
import getMdPadding from '../padding/getMdPadding';
import getSmPadding from '../padding/getSmPadding';
import getXlPadding from '../padding/getXlPadding';
import getXsPadding from '../padding/getXsPadding';
import SettingsProps from '../types/SettingsProps';
import classNames from '@/external/classNames';

const getButtonSize = ({
  size,
  iconLeft,
  iconRight,
  fullWidth,
}: SettingsProps): string => {
  if (size === 'xs') {
    return classNames(
      getXsPadding({ iconLeft, iconRight, fullWidth }),
      'h-6 py-1 gap-1 text-moon-12 rounded-moon-i-xs',
    );
  }
  if (size === 'sm') {
    return classNames(
      getSmPadding({ iconLeft, iconRight, fullWidth }),
      'h-8 py-1 gap-1 text-moon-14 rounded-moon-i-sm',
    );
  }
  if (size === 'lg') {
    return classNames(
      getLgPadding({ iconLeft, iconRight, fullWidth }),
      'h-12 py-3 gap-2 text-moon-16 rounded-moon-i-sm',
    );
  }
  if (size === 'xl') {
    return classNames(
      getXlPadding({ iconLeft, iconRight, fullWidth }),
      'h-14 py-4 gap-2 text-moon-16 rounded-moon-i-md',
    );
  }
  return classNames(
    getMdPadding({ iconLeft, iconRight, fullWidth }),
    'h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm',
  );
};

export default getButtonSize;
