import classNames from '../classNames';

type Props = {
  className?: string;
  onClose?: () => void;
};

const Backdrop: FC<Props> = ({ className, onClose }) => (
  <div
    className={classNames('fixed -z-1 inset-0 bg-zeno', className)}
    aria-hidden="true"
    onClick={onClose}
  />
);

export default Backdrop;
