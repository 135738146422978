import AnimationContent from './animation/AnimationContent';
import IconLeft from './styles/IconLeft';
import IconRight from './styles/IconRight';
import Props from './types/Props';

const ButtonContent: FC<Props> = ({
  children,
  variant = 'fill',
  size = 'md',
  iconLeft,
  iconRight,
  fullWidth,
  animation,
}) => {
  const hasAnimationContent =
    animation === 'progress' || animation === 'success';

  if (hasAnimationContent) {
    return (
      <AnimationContent
        iconLeft={iconLeft}
        iconRight={iconRight}
        animation={animation}
        size={size}
        fullWidth={fullWidth}
        variant={variant}
      >
        {children}
      </AnimationContent>
    );
  }
  return (
    <>
      {iconLeft && (
        <IconLeft fullWidth={fullWidth} iconLeft={iconLeft} size={size} />
      )}
      {children}
      {iconRight && (
        <IconRight fullWidth={fullWidth} iconRight={iconRight} size={size} />
      )}
    </>
  );
};

export default ButtonContent;
