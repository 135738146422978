import classNames from '@/external/classNames';

type HoverProps = {
  className?: string;
};
const Hover = ({ className }: HoverProps) => (
  <span
    aria-hidden="true"
    className={classNames(
      'hover z-[-1] block absolute inset-0 pointer-events-none',
      'transition-[background-color_0.2s_ease-in-out]',
      'hover:bg-heles',
      className,
    )}
  />
);

export default Hover;
