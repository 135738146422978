import ButtonContent from './ButtonContent';
import ButtonComponent from './styles/ButtonComponent';
import Hover from './styles/Hover';
import Props from './types/Props';
import Variants from './types/Variants';
import classNames from '@/external/classNames';

const Button: FC<Props> = ({
  children,
  variant = 'fill',
  size = 'md',
  iconLeft,
  iconRight,
  fullWidth,
  disabled,
  animation,
  as,
  className,
  ...rest
}) => {
  const customClassName = classNames(
    'text-goten bg-piccolo',
    variant === 'secondary' &&
      'text-bulma bg-transparent ring-inset ring-1 ring-trunks hover:ring-bulma',
    variant === 'tertiary' && 'text-goten bg-hit',
    variant === 'ghost' && 'text-goten bg-transparent hover:ring-0 ring-0',
    className,
  );

  return (
    <ButtonComponent
      size={size}
      variant={variant as Variants}
      iconLeft={iconLeft}
      iconRight={iconRight}
      fullWidth={fullWidth}
      disabled={disabled}
      animation={animation}
      as={as}
      customClassName={customClassName}
      {...rest}
    >
      <ButtonContent
        variant={variant}
        size={size}
        iconLeft={iconLeft}
        iconRight={iconRight}
        fullWidth={fullWidth}
        animation={animation}
      >
        {children}
      </ButtonContent>
      <Hover />
    </ButtonComponent>
  );
};

export default Button;
