import { ReactNode } from 'react';
import classNames from '@/external/classNames';

type Props = {
  children?: ReactNode;
  className?: string;
};

const Title: FC<Props> = ({ children, className }) => (
  <span
    className={classNames('text-moon-18 font-medium text-gray-900', className)}
  >
    {children}
  </span>
);

export default Title;
