import getIconHorizontalPosition from './getIconHorizontalPosition';
import getIconSize from '../getSize/getIconSize';
import SettingsProps from '../types/SettingsProps';
import classNames from '@/external/classNames';

const IconLeft = ({ fullWidth, iconLeft, size }: SettingsProps) => (
  <span
    aria-hidden="true"
    className={classNames(
      getIconSize(size),
      fullWidth &&
        `absolute block top-1/2 translate-y-[-50%] ${getIconHorizontalPosition({
          iconLeft,
          size,
        })}`,
    )}
  >
    {iconLeft}
  </span>
);

export default IconLeft;
