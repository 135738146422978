import { ElementType } from 'react';
import getButtonCommonStyles from './getButtonCommonStyles';
import getButtonVariants from './getButtonVariants';
import getAnimation from '../animation/getAnimation';
import getButtonSize from '../getSize/getButtonSize';
import Props from '../types/Props';
import classNames from '@/external/classNames';

const ButtonComponent: FC<Props> = ({
  variant,
  size,
  iconLeft,
  iconRight,
  fullWidth,
  disabled,
  animation,
  as,
  customClassName,
  ...rest
}) => {
  const Component = (as || 'button') as ElementType;
  return (
    <Component
      className={classNames(
        getButtonSize({
          size,
          iconLeft,
          iconRight,
          fullWidth,
        }),
        getButtonCommonStyles(disabled),
        getButtonVariants({ variant, disabled }),
        animation === 'pulse' && getAnimation('pulse'),
        animation === 'error' && getAnimation('error'),
        fullWidth && 'w-full',
        customClassName,
      )}
      {...((!as || as === 'button') && { type: 'button' })}
      {...(disabled && { disabled })}
      {...rest}
    />
  );
};

export default ButtonComponent;
