'use client';
import { useEffect, useState } from 'react';
import Panel from './Panel';
import Title from './Title';
import classNames from '../../classNames';
import Backdrop from '../Backdrop';

type Props = {
  open: boolean;
  className?: string;
};

const ModalRoot: FC<Props> = ({ open, children, className }) => {
  const [isVisible, setIsVisible] = useState(open);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
    } else {
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [open]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={classNames(
        'fixed inset-0 z-10 flex items-center justify-center',
        className,
      )}
    >
      <div
        className={classNames(
          'transition-opacity duration-300',
          open ? 'opacity-100' : 'opacity-0',
        )}
      >
        {children}
      </div>
    </div>
  );
};

const Modal = Object.assign(ModalRoot, {
  Backdrop,
  Title,
  Panel,
});

export default Modal;
