import { GenericCheckAlternative } from '@heathmont/moon-icons-tw';
import Loader from '../../Loader';
import getIconSize from '../getSize/getIconSize';
import getLoaderSize from '../getSize/getLoaderSize';
import getLoaderColor from '../styles/getLoaderColor';
import IconLeft from '../styles/IconLeft';
import IconRight from '../styles/IconRight';
import SettingsProps from '../types/SettingsProps';
import classNames from '@/external/classNames';

const AnimationContent: FC<SettingsProps> = ({
  children,
  iconLeft,
  iconRight,
  animation,
  size,
  fullWidth,
  variant,
}) => (
  <span className="block h-full pointer-events-none">
    <span
      className={classNames(
        'flex absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 content-center',
        'justify-center',
      )}
    >
      {animation === 'progress' && (
        <Loader size={getLoaderSize(size)} color={getLoaderColor(variant)} />
      )}
      {animation === 'success' && (
        <GenericCheckAlternative
          aria-label="Success"
          className={getIconSize(size)}
        />
      )}
    </span>
    <span className="flex gap-2 items-center opacity-0">
      {iconLeft && (
        <IconLeft fullWidth={fullWidth} iconLeft={iconLeft} size={size} />
      )}
      {children}
      {iconRight && (
        <IconRight fullWidth={fullWidth} iconRight={iconRight} size={size} />
      )}
    </span>
  </span>
);

export default AnimationContent;
