import Sizes from '../types/Sizes';

const getIconSize = (size?: Sizes): string => {
  if (size === 'xs') {
    return 'text-moon-16';
  }
  return 'text-moon-24';
};

export default getIconSize;
