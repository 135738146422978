import Sizes from '../types/Sizes';
import type LoaderSize from '@/external/components/Loader/LoaderSize';

const getLoaderSize = (size?: Sizes): LoaderSize => {
  if (size === 'xs') {
    return '2xs';
  }
  return 'xs';
};

export default getLoaderSize;
